import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid

import { SystemBalanceSignal } from 'features/GTSPanel/types';
import { useState } from 'react';
import { ColDef } from 'ag-grid-enterprise';
import { useSystemBalanceSignalTable } from 'features/GTSPanel/gtsdataApi';
import { Loader } from 'components';
import styles from '../../GTSPanel.module.css';
import { formatFromUtc } from 'utils/dateFormating';

const SystemBalanceSignalTable = () => {
  const { isLoading, data } = useSystemBalanceSignalTable();

  const [columnDefs] = useState<ColDef<SystemBalanceSignal>[]>([
    { field: 'datePartMET', headerName: 'Date', width: 115 },
    { field: 'timePartMET', headerName: 'Time', width: 70 },
    { field: 'positionEnergyAmount', headerName: 'Accountable', width: 130 },
    { field: 'sumHelpersEnergyAmount', headerName: 'Sum Helpers', width: 130 },
    { field: 'sumCausersEnergyAmount', headerName: 'Sum Causers', width: 130 },
    {
      field: 'bufferZone03BorderMin',
      headerName: 'Low border orange zone',
      cellClass: styles.orange,
    },
    {
      field: 'bufferZone02BorderMin',
      headerName: 'Low border light green zone',
      cellClass: styles.lightGreen,
    },
    {
      field: 'bufferZone01BorderMin',
      headerName: 'Low border dark green zone',
      cellClass: styles.darkGreen,
    },
    {
      field: 'bufferZone01BorderMax',
      headerName: 'Upper border dark green zone',
      cellClass: styles.darkGreen,
    },
    {
      field: 'bufferZone02BorderMax',
      headerName: 'Upper border light green zone',
      cellClass: styles.lightGreen,
    },
    {
      field: 'bufferZone03BorderMax',
      headerName: 'Upper border orange zone',
      cellClass: styles.orange,
    },
  ]);

  return !isLoading && data ? (
    <div className={`${styles.gridReact} ag-theme-quartz`}>
      <h1 className={styles.gridReactTitle}>System Balance Signal</h1>
      <AgGridReact<SystemBalanceSignal>
        rowData={data.systemBalanceSignalTable}
        columnDefs={columnDefs}
      />
      <h2>Last updated {formatFromUtc(new Date(data.lastUpdated))}</h2>
    </div>
  ) : (
    <Loader />
  );
};

export default SystemBalanceSignalTable;
