import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  MessageDefIcon,
  MessageHistory,
  BoxIcon,
  FourSquaresIcon,
  AreasIcon,
  CalculatorIcon,
  LineChart,
} from 'assets/icons';
import { NavigationItem, ExpandedMenuItemLabel } from './styled';
import { NavigationItemsProps } from './types';
import styled from 'styled-components';
import { useCheckAuthority } from 'utils/hooks/useCheckAuthority';

const AckIcon = styled(MessageHistory)`
  path {
    fill: ${({ theme }) => theme.extraRed}!important;
  }
`;

const ConnectionTestIcon = styled(AreasIcon)`
  path {
    fill: ${({ theme }) => theme.extraRed}!important;
  }
`;
// TODO: I think Navigation should be placed in feature directory
export const NavigationItems = ({
  closeModal,
  isMenuExpanded,
  isMobile,
}: NavigationItemsProps): ReactElement => {
  const { t } = useTranslation('navigation');
  const location = useLocation();
  const isSysAdmin = useCheckAuthority(['SYSADMIN']);
  const isSelected = (path: string): boolean => location.pathname === path;
  const closeNavigation = (): void => {
    if (isMobile) {
      closeModal();
    }
  };
  return (
    <div>
      <NavigationItem
        $isMenuExpanded={isMenuExpanded}
        to="/app/"
        $isSelected={isSelected('/app/')}
        onClick={closeNavigation}
      >
        <FourSquaresIcon />
        <ExpandedMenuItemLabel isVisible={isMenuExpanded}>
          <>{t('dashboard')}</>
        </ExpandedMenuItemLabel>
      </NavigationItem>
      <NavigationItem
        $isMenuExpanded={isMenuExpanded}
        to="/app/nominations/"
        $isSelected={isSelected('/app/nominations/')}
        onClick={closeNavigation}
      >
        <CalculatorIcon />
        <ExpandedMenuItemLabel isVisible={isMenuExpanded}>
          <>{t('nomination')}</>
        </ExpandedMenuItemLabel>
      </NavigationItem>
      <NavigationItem
        $isMenuExpanded={isMenuExpanded}
        to="/app/history/"
        $isSelected={isSelected('/app/history/')}
        onClick={closeNavigation}
      >
        <MessageHistory />
        <ExpandedMenuItemLabel isVisible={isMenuExpanded}>
          <>{t('messageHistory')}</>
        </ExpandedMenuItemLabel>
      </NavigationItem>
      <NavigationItem
        $isMenuExpanded={isMenuExpanded}
        to="/app/masterdata/"
        $isSelected={isSelected('/app/masterdata/')}
        onClick={closeNavigation}
      >
        <BoxIcon />
        <ExpandedMenuItemLabel isVisible={isMenuExpanded}>
          <>{t('masterdataPage')}</>
        </ExpandedMenuItemLabel>
      </NavigationItem>
      <NavigationItem
        $isMenuExpanded={isMenuExpanded}
        to="/app/message-definitions/"
        $isSelected={isSelected('/app/message-definitions/')}
        onClick={closeNavigation}
      >
        <MessageDefIcon />
        <ExpandedMenuItemLabel isVisible={isMenuExpanded}>
          <>{t('messageDefinitionPage')}</>
        </ExpandedMenuItemLabel>
      </NavigationItem>
      <NavigationItem
        $isMenuExpanded={isMenuExpanded}
        to="/app/areas/"
        $isSelected={isSelected('/app/areas/')}
        onClick={closeNavigation}
      >
        <AreasIcon />
        <ExpandedMenuItemLabel isVisible={isMenuExpanded}>
          <>{t('areasTopologyPage')}</>
        </ExpandedMenuItemLabel>
      </NavigationItem>
      <NavigationItem
        $isMenuExpanded={isMenuExpanded}
        to="/app/gts/"
        $isSelected={isSelected('/app/gts/')}
        onClick={closeNavigation}
      >
        <LineChart />
        <ExpandedMenuItemLabel isVisible={isMenuExpanded}>GTS B2B Services</ExpandedMenuItemLabel>
      </NavigationItem>
      {isSysAdmin && (
        <>
          <NavigationItem
            $isMenuExpanded={isMenuExpanded}
            to="/app/ack/"
            $isSelected={isSelected('/app/ack/')}
            onClick={closeNavigation}
          >
            <AckIcon />
            <ExpandedMenuItemLabel isVisible={isMenuExpanded}>
              <>{t('ackPage')}</>
            </ExpandedMenuItemLabel>
          </NavigationItem>
          <NavigationItem
            $isMenuExpanded={isMenuExpanded}
            to="/app/admin-panel/"
            $isSelected={isSelected('/app/admin-panel')}
            onClick={closeNavigation}
          >
            <ConnectionTestIcon />
            <ExpandedMenuItemLabel isVisible={isMenuExpanded}>
              <>{t('adminPanel')}</>
            </ExpandedMenuItemLabel>
          </NavigationItem>
        </>
      )}
    </div>
  );
};
