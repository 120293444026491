import { OperationalAccountablePos, OperationalPrognosisPos } from 'features/GTSPanel/types';
import styled from 'styled-components';
import { formatFromUtc } from 'utils/dateFormating';

const PrognosisWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    text-align: left;
    width: 50%;
  }
`;

interface PrognosisProps {
  lastValueAccountable: OperationalAccountablePos;
  lastValuePrognosis: OperationalPrognosisPos;
}

export const Prognosis = ({ lastValueAccountable, lastValuePrognosis }: PrognosisProps) => {
  return (
    <PrognosisWrapper>
      <h1>Prognosis for {formatFromUtc(new Date(lastValuePrognosis.posDateTime))}</h1>

      <p>
        Last value prognosis: {'  '}
        {lastValuePrognosis.energyAmount}[{lastValuePrognosis.energyUnit}]
      </p>
      <p>
        Last value accountable: {lastValueAccountable.positionEnergyAmount}[
        {lastValueAccountable.positionEnergyUnit}]
      </p>
    </PrognosisWrapper>
  );
};
