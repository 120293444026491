import { AgCharts } from 'ag-charts-react';
import { AgChartOptions } from 'ag-charts-community';
import { usePortfolioImbalanceSignal } from 'features/GTSPanel/gtsdataApi';
import { Loader } from 'components/Loaders';
import { formatFromUtc } from 'utils/dateFormating';
import styled from './ImbalanceSignal.module.css';
import { Prognosis } from './components/Prognosis';

interface ImbalanceSignalProps {
  portfolioEdigasId?: string;
}

const ImbalanceSignal = ({ portfolioEdigasId }: ImbalanceSignalProps) => {
  const { isLoading, data } = usePortfolioImbalanceSignal(portfolioEdigasId);

  const options: AgChartOptions = {
    title: {
      text: 'Portfolio Imbalance Signal',
    },
    footnote: {
      text: data?.lastUpdated && `Last updated ${formatFromUtc(new Date(data.lastUpdated))}`,
    },
    data: data?.operationalAccountablePosList,
    series: [
      {
        type: 'bar',
        xKey: 'posDateTime',
        xName: 'Time',
        yKey: 'positionEnergyAmount',
        yName: portfolioEdigasId,
        fill: '#c6d5dd',
      },
    ],
    axes: [
      {
        type: 'number',
        position: 'left',
        title: { text: 'Energy (kWh)' },
        crosshair: {
          enabled: false,
        },
      },
      {
        type: 'time',
        position: 'bottom',
        interval: { step: 3600000 },
        title: { text: 'Time' },
        label: {
          avoidCollisions: true,
          rotation: -45,
        },
        crosshair: {
          enabled: false,
        },
      },
    ],
    legend: {
      enabled: true,
      position: 'bottom',
    },
  };

  return !isLoading && data ? (
    <div className={styled.wrapper}>
      <AgCharts options={options} />
      <Prognosis
        lastValueAccountable={
          data.operationalAccountablePosList[data.operationalAccountablePosList.length - 1]
        }
        lastValuePrognosis={data.operationalPrognosisPos}
      />
    </div>
  ) : (
    <Loader />
  );
};

export default ImbalanceSignal;
