import axios from 'axios';
import { TENANT_NAME } from './constants';

axios.defaults.withCredentials = false;
axios.defaults.maxRedirects = 0;
axios.defaults.headers.get = {
  'X-Tenant-ID': TENANT_NAME(),
  Authorization: 'Bearer asdasd',
};

export default axios;
