import { useQuery } from 'react-query';
import { Counterparty } from 'utils/types';
import { HOST_GTS_NAME } from 'utils/constants';
import axios from 'utils/axios';
import {
  Portfolio,
  PortfolioImbalanceSignalResponse,
  SystemBalanceSignalGraphicResponse,
  SystemBalanceSignalTableResponse,
} from './types';
export interface Counterparties {
  content: Array<Counterparty>;
  totalPages: number;
}

export const useSystemBalanceSignalTable = () => {
  const fetchData = (): Promise<SystemBalanceSignalTableResponse> => {
    return axios
      .get<SystemBalanceSignalTableResponse>(`${HOST_GTS_NAME()}/gts/systembalancesignal/table`)
      .then((res) => {
        res.data.systemBalanceSignalTable = res.data.systemBalanceSignalTable.map((e) => ({
          ...e,
          sbsDateTime: new Date(e.sbsDateTime).getTime(),
          bufferZone04BorderMin: -80000000,
          bufferZone04BorderMax: 80000000,
        }));

        return res.data;
      });
  };

  return useQuery<SystemBalanceSignalTableResponse, Error>(
    ['SystemBalanceSignalTable'],
    fetchData,
    {
      keepPreviousData: true,
    },
  );
};

export const useSystemBalanceSignalGraphic = () => {
  const fetchData = (): Promise<SystemBalanceSignalGraphicResponse> => {
    return axios
      .get<SystemBalanceSignalGraphicResponse>(`${HOST_GTS_NAME()}/gts/systembalancesignal/graphic`)
      .then((res) => {
        res.data.systemBalanceSignalTable = res.data.systemBalanceSignalTable.map((e) => ({
          ...e,
          sbsDateTime: new Date(e.sbsDateTime).getTime(),
          bufferZone04BorderMin: -80000000,
          bufferZone04BorderMax: 80000000,
        }));

        return res.data;
      });
  };

  return useQuery<SystemBalanceSignalGraphicResponse, Error>(
    ['SystemBalanceSignalGraphic'],
    fetchData,
    {
      keepPreviousData: true,
    },
  );
};

export const usePortfolioImbalanceSignal = (portfolioEdigasId: string | undefined) => {
  const fetchData = (): Promise<PortfolioImbalanceSignalResponse> => {
    return axios
      .get<PortfolioImbalanceSignalResponse>(
        `${HOST_GTS_NAME()}/gts/portfolioimbalancesignal/graphic?portfolioId=${portfolioEdigasId}`,
      )
      .then((res) => {
        res.data.operationalAccountablePosList = res.data.operationalAccountablePosList.map(
          (e) => ({
            ...e,
            origPosDateTime: e.posDateTime,
            posDateTime: new Date(e.posDateTime).getTime(),
          }),
        );

        return res.data;
      });
  };

  return useQuery<PortfolioImbalanceSignalResponse, Error>(
    ['PortfolioImbalanceSignal'],
    fetchData,
    {
      keepPreviousData: true,
      enabled: !!portfolioEdigasId,
    },
  );
};

export const usePortfolio = () => {
  const fetchData = (): Promise<Portfolio> => {
    return axios.get<Portfolio[]>(`${HOST_GTS_NAME()}/gts/portfolios`).then((res) => {
      return res.data[0];
    });
  };

  return useQuery<Portfolio, Error>(['Portfolio'], fetchData, {
    keepPreviousData: true,
  });
};
