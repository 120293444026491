import { AgCharts } from 'ag-charts-react';
import { AgChartOptions } from 'ag-charts-community';
import 'ag-charts-enterprise';
import { useSystemBalanceSignalGraphic } from 'features/GTSPanel/gtsdataApi';
import { Loader } from 'components/Loaders';
import { formatFromUtc } from 'utils/dateFormating';
import styles from './SystemBalanceSignalGraphic.module.css';

const SystemBalanceSignalGraphic = () => {
  const { isLoading, data } = useSystemBalanceSignalGraphic();

  const options: AgChartOptions = {
    title: {
      text: `System Balance Signal`,
    },
    footnote: {
      text: data?.lastUpdated && `Last updated ${formatFromUtc(new Date(data.lastUpdated))}`,
    },
    data: !isLoading && data ? data.systemBalanceSignalTable : [],
    series: [
      {
        type: 'range-area',
        xKey: 'sbsDateTime',
        xName: 'Time',
        yLowKey: 'bufferZone04BorderMin',
        yHighKey: 'bufferZone04BorderMax',
        yLowName: 'Border min',
        yHighName: 'Border max',
        stroke: '#E57373',
        fill: '#E57373',
        yName: 'Red zone',
        showInLegend: false,
      },
      {
        type: 'range-area',
        xKey: 'sbsDateTime',
        xName: 'Time',
        yLowKey: 'bufferZone03BorderMin',
        yHighKey: 'bufferZone03BorderMax',
        yLowName: 'Border min',
        yHighName: 'Border max',
        stroke: '#FFB74D',
        fill: '#FFB74D',
        yName: 'Low Border orange zone',
      },
      {
        type: 'range-area',
        xKey: 'sbsDateTime',
        xName: 'Time',
        yLowKey: 'bufferZone02BorderMin',
        yHighKey: 'bufferZone02BorderMax',
        yLowName: 'Border min',
        yHighName: 'Border max',
        stroke: '#AED581',
        fill: '#AED581',
        yName: 'Low Border light green zone',
      },
      {
        type: 'range-area',
        xKey: 'sbsDateTime',
        xName: 'Time',
        yLowKey: 'bufferZone01BorderMin',
        yHighKey: 'bufferZone01BorderMax',
        yLowName: 'Border min',
        yHighName: 'Border max',
        stroke: '#5CB85C',
        fill: '#5CB85C',
        yName: 'Low Border dark green zone',
      },
      {
        type: 'line',
        xKey: 'sbsDateTime',
        yKey: 'positionEnergyAmount',
        stroke: '#455A64',
        marker: { shape: 'triangle', fill: '#FFF' },
        yName: 'SBS Accountable',
      },
      {
        type: 'line',
        xKey: 'sbsDateTime',
        yKey: 'sumCausersEnergyAmount',
        stroke: '#D32F2F',
        marker: { shape: 'circle', fill: '#ff0000' },
        yName: 'Sum Causers',
      },
      {
        type: 'line',
        xKey: 'sbsDateTime',
        yKey: 'sumHelpersEnergyAmount',
        stroke: '#64B5F6',
        marker: { shape: 'circle', stroke: '#1a1adb', fill: '#1a1adb' },
        yName: 'Sum Helpers',
      },
    ],
    axes: [
      {
        type: 'number',
        position: 'left',
        max: 80000000,
        min: -80000000,
        interval: { step: 100 },
        title: { text: 'Energy (kWh)' },
        // gridLine: { enabled: true },
        crosshair: {
          enabled: true,
          snap: true,
          // stroke: 'yellow',
          label: {
            enabled: true,
            renderer: (e) => {
              return {
                text: e.value.toLocaleString('de-DE'),
                color: '#09f',
                backgroundColor: '#edf1f4',
                opacity: 1,
              };
            },
          },
        },
      },
      {
        type: 'time',
        position: 'bottom',
        interval: { step: 3600000 },
        title: { text: 'Time' },
        // gridLine: { enabled: true },
        crosshair: {
          enabled: true,
          label: {
            enabled: true,
            renderer: (e) => {
              return {
                // text: e.value.toLocaleString('de-DE'),
                color: '#09f',
                backgroundColor: '#edf1f4',
                opacity: 1,
              };
            },
          },
        },
      },
    ],
    legend: {
      enabled: true,
      position: 'right',
    },
  };

  return !isLoading && data ? <AgCharts options={options} /> : <Loader />;
};

export default SystemBalanceSignalGraphic;
